<template>
    <div>
        <!-- <button v-if="!show" class="badge btn-prime btn-xs" @click="showtable=!showtable">Active Complaint</button> -->
        <h5 class="text-center">Sale History</h5>
        <table class="table table-bordered table-font" style="margin-bottom:0;color:#30bf00;font-size:13px!important;">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Sale Description</th>
                    <th>Sale Date</th>
                    <th>Entered BY</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" v-bind:key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.productdetails }}</td>
                    <td>{{ moment(item.saledate).format("DD-MM-YYYY") }}</td>
                    <td>{{ item.enteredby != null ? item.enteredby.name : '' }}<br>
                        {{ moment(item.created_at).format("DD-MM-YYYY") }}</td>
                </tr>
            </tbody>
        </table>
        <div class="col-md-12" v-if="(item.followedid == loggedinuser.id || loggedinuser.role > 0) && (show)">
            <div class="panel panel-cascade">
                <div class="panel-body" style="background-color:#d8e6ec">
                    <div class="form-horizontal cascde-forms">
                        
                        <div class="form-group row" v-if="show">
                            <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                                Product Description<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                            <div class="col-lg-6 col-md-9">
                                <textarea class="form-control form-cascade-control input-small" v-model="productdescription" cols="20"
                                    id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                            </div>
                            <!-- <div class="col-lg-2">
                            <button class="badge btn-prime btn-xs" @click="shownewlead=!shownewlead">New Lead</button>
                        </div> -->
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-md-3 control-label" style="color:#0b6969;">Sale Date<span
                                    style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                            <div class="col-lg-6 col-md-9">
                                <!-- <input class="form-control form-cascade-control input-small" :disabled="showdate" v-model="scheduledate"  type="date" > -->
                                <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                    v-model="saledate">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div class="flex items-center">
                                            <button type="button" class="date-picker" @click="togglePopover()">
                                                <i class='bx bx-calendar'></i>
                                            </button>
                                            <input :value="inputValue" @click="togglePopover()"
                                                class="form-control form-cascade-control input-small"
                                                placeholder="DD/MM/YYYY" readonly />
                                        </div>
                                    </template>
                                </vc-date-picker>
                            </div>
                            <!-- <div class="col-lg-2" >
                            <button class="badge btn-prime btn-xs"  @click="showdate=!showdate">Schedule</button>
                        </div> -->
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3"></div>
                        <div class="col-md-5 flex-around-row">
                            <button type="button" class="btn btn-success" style="margin-top:0"
                                @click="submit()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Constants from '../../components/utilities/Constants.vue';
export default {
    mixins: [Constants],
    props: ['item', 'show'],
    data() {
        return {
            id: 0, 
            list: [],
            moment: moment,
            productdescription:'',
            saledate:'',
            requesttype: 5,
            // shownewlead:false,
            newleadremark: '',
            showtable: false,
            showdate: true,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
        }
    },
    validations: {
        scheduledate: {
            required
        }
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters([
            'loggedinuser', 'employees'
        ]),
    },
    methods: {
        refresh() {
            if (this.item != null) {
                this.requesttype = this.item.requesttype
                this.saledate = this.item.saledate
                this.productdescription = this.item.productdescription
            }
            let param = { leadid: this.item.id }
            this.$store.commit('assignloadingstatus', 1)
            this.$http.post('api/leadssales/fetch', param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
        },
        processDetailResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            this.list = data
        },
        submit() {
            let param = {
                leadid: this.item.id, productdetails: this.productdescription, saledate: this.saledate,
                requesttype: this.requesttype, 
            }
            this.$http.post('api/leadssales/createupdate', param)
                .then((response) => this.processDetail(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
            
        },
        
        processDetail() {
            this.$parent.visitcommentssale = false

            this.$parent.refresh();
        },
        requestmethod(item) {
            let type = "";
            switch (item.requesttype) {
                case 1:
                    type = "QUERY";
                    break;
                case 2:
                    type = "Complaint";
                    break;
                case 3:
                    type = "Feedback";
                    break;
                case 4:
                    type = "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },

        errorShow() {
            this.$store.commit('assignloadingstatus', 0)
            alert("Something went wrong, please try after sometime.")
        }
    }
}
</script>