<template>
<tbody class="table-font" style="font-size:13px;" v-if="item!=null">
    <tr :style="item.isfake|| item.isrelevant?'color:red':''">
        <td>{{index+1}}</td>
        <!-- <td>{{item.customer?.generatedid}}</td> -->
        <td>{{item.customername}}</td>
        <td>{{item.customermobile}}</td>
        <td>{{item.alternatemobile}}</td>
        <td>{{item.customeraddress}}</td> 
        <td>{{moment(item.dob).format('DD-MM-YYYY')}}</td>
        <td>{{item.refrence}}</td>
        <!-- <td>{{item.complaintno}}</td>  -->
        <td>{{item.remark}}</td>
        
        <!-- <td style="font-size:10px ;">
            <div>
                <div>
                    <span v-if="item.product!=null && item.product.category!=null">{{item.product.category.name}}</span>
                    <span v-if="item.product!=null && item.product.attribute!=null">
                        <div v-for="company in item.product.attribute" :key="company.id">
                            {{ getcompany(company.attribute_value_ids) }}
                        </div>
                    </span>
                    <span >{{item.product.name}}</span>&nbsp;
                </div>
            </div>
        </td> -->
        <td style="color:chocolate"><i class="fa fa-calendar"></i> {{item.scheduledate!=null?moment(item.scheduledate).format('DD-MM-YYYY'):"N.A"}}</td>
        
        <td><span v-if="item.advisor!=null">{{item.advisor.name}}<br>
            {{ item.advisor.generatedid }}<br>
           </span>
        </td>
        <td>{{getCompanyMasterLabelByType(item.refrenceid,COMPANY_CUSTOMER_REFRENCE)}}</td>
        <td><span v-if="item.enteredby!=null">{{item.enteredby.name}}<br>
            {{moment(item.created_at).format('DD-MM-YYYY')}}<br></span>
            {{ moment(item.created_at).format('h:m:s A') }}
        </td>
     
        <td><span style="background-color: #b4e887;border: 1px solid;padding:2px;">{{item.ratings}} </span>
            <span v-for="item in item.ratings" :key="item">
              <i class='bx bxs-star' style='color:#eff300'></i>
            </span>
        </td>
        <td v-if="loginusercheck(193)"><button class="badge btn-prime btn-xs" @click="showchats()">conversation</button></td>
        <td><button class="badge btn-success btn-xs" @click="showSalechats()">Sale History</button></td>
        <td v-if="loginusercheck(196)"> 
            <button class="badge btn-danger btn-xs" v-if="item.isrelevant==1" @click="updatefakemodal(item)" >Not Relevant.</button>
            <button class="badge btn-success btn-xs" v-if="item.isrelevant==0" @click="updatefakemodal(item)" >Relevant</button>
        </td>
        <td> <button class="badge btn-prime btn-xs" @click="edit(item)" >Edit</button></td>
        <td @dblclick="leadnatureupdate(item)" @keyup.enter="leadnatureupdate(item)">
            <select v-if="naturetypefield" class="form-control form-cascade-control input-small" v-model="item.naturetype" @change="leadnatureupdate(item)">
                <option :value="0">----Select---</option>
                <option v-for="option in getcompanymastertype(COMPANY_LEAD_NATURE)"
                    v-bind:key="option.labelid" v-bind:value="option.labelid"
                    v-text="option.label" />
            </select>
            {{ leadnaturetype(item.naturetype) }}
        </td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="15">
            <customer-conversation :item="item" :show="true"></customer-conversation>
        </td>
    </tr>
    <tr v-if="visitcommentssale">
        <td colspan="15">
            <lead-sale :item="item" :show="true"></lead-sale>
        </td>
    </tr>
</tbody>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import CustomerConversation from '../leads/CustomerConversation.vue'
import Constants from '../../components/utilities/Constants.vue'
import LeadSale from './LeadSale.vue'
export default {
    props:['item','index'],
    components:{CustomerConversation,LeadSale},
    mixins:[Constants],
    data() {
        return {
            moment:moment,
            visitcomments:false,
            visitcommentssale:false,
            followedid:0,
            showfiled:false,
            naturetypefield:false
        }
    },
    computed:{
        ...mapGetters(['employees','loggedinuser','attributes'])
    },
    mounted() {
        if(this.item.followby!=null){
            this.followedid=this.item.followby.id
        }
    },
    methods: {
        showSalechats(){
            this.visitcommentssale=!this.visitcommentssale
        },
        showchats(){
            this.visitcomments=!this.visitcomments
        },
        requestmethod(item){
            let type="";
            switch (item.requesttype) {
                case 1:
                    type= "QUERY";
                    break;
                case 2:
                    type= "Complaint";
                    break;
                case 3:
                    type= "Feedback";
                    break;
                case 4:
                    type= "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },
        leadbymethod(item){
            let type="";
            switch (item.leadtype) {
                case 1:
                    type= "INCOMING CALL";
                    break;
                case 2:
                    type= "OUTGOING CALL";
                    break;
                case 3:
                    type= "NEWS PAPER ADD";
                    break;
                case 4:
                    type= "SHOW ROOM WALKIN";
                    break;
                case 5:
                    type= "HOME VISIT";
                    break;
                case 6:
                    type= "REFFERRED BY OTHER";
                    break;
                case 7:
                    type= "INTERNET";
                    break;
                case 8:
                    type= "BY ADVISOR";
                    break;
                default:
                    break;
            }
            return type;
        },
        leadnatureupdate(item){
            this.naturetypefield=!this.naturetypefield
            if(confirm("Do ypu want to update nature of lead")){

                let param={id:item.id,column:"naturetype",value:item.naturetype}
                this.$http.post('api/leads/updatefield',param)
                .then((response) => this.processnatureUpdateResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });            
            }
            
        },
        edit(item){
            this.$store.commit('assignleadsedit',item)
            this.$store.commit('assignviewno',2)
        },
        updatefakemodal(item){
            this.$store.commit('assignedititem',item)
            this.$modal.show('leadfakemodal')
        },
        processnatureUpdateResponse(){
            this.$notify({
                text: 'Details Saved Successfully',
                type: 'success',
                duration: '-1',
                speed: '100',
                closeOnClick: false
            })
            this.$parent.refresh();
        },
        refresh(){
            this.$parent.refresh()
        },
        // updatefield(){

        // },
        showfollowedfield(item){
            if(this.loginusercheck(170)){
                this.showfiled=!this.showfiled
                let param={id:item.id,column:"followedid",value:this.followedid}
                this.$http.post('api/leads/updatefield',param)
                    .then((response) =>this.processUpdateResponse(response.data))
                    .catch((err) => {
                        this.errorShow(err)
                    });
            }else{
                alert('You Dont have Right')
            }
        },
        processUpdateResponse(data){
            // this.showfiled=false
            this.$parent.refresh()
            this.$notify({
                text:'Field Updated Successfully',data,
                type:'success',
                duration:'-1',
                speed:'100',
                closeOnClick:false
            })
        },
        errorShow(){
            this.$store.commit('assignloadingstatus',0)
            alert("Something went wrong, please try after sometime.")
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
    },
}
</script>